/* 3rd party imports */
import { SxStyleProp } from 'theme-ui';

const header: SxStyleProp = {
	color: 'primary',
}

const address: SxStyleProp = {
	fontStyle: 'normal',
	'a': {
		color: 'link'
	},
}

export default { header, address };