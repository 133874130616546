/** @jsx jsx */
/* 3rd party imports */
import { FunctionComponent } from 'react';
import { jsx } from 'theme-ui';

/* 1st party imports */
import useSiteMetadata from '@/hooks/useSiteMetadata';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import Styles from '@/pages/contact/styles';

const Page: FunctionComponent = () => {
	const { author, email } = useSiteMetadata();

	return (
		<Layout>
			<SEO title="Contact" />
			<h1 sx={Styles.header}>Contact</h1>
			<address sx={Styles.address}>
				Name: {author}
				<br/>
				Email: <a href={`mailto:${email}`}>{email}</a>
				<br/>
				City: Nottingham &amp; Todmorden, United Kingdom
			</address>
		</Layout>
	);
};

export default Page;
